<template>
    <div id="text-asset-modal" uk-modal bg-close="false" class="">
      <div class="uk-modal-dialog">
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-left">{{ isUpdate ? $t('Pages.AppEdit.Labels.EditTextAsset') : $t( 'Pages.AppEdit.Labels.TextAsset' ) }}</h2>
        </div>
        <div class="uk-modal-body">
          <form class="uk-form-horizontal uk-margin-large uk-text-left" @submit.prevent="saveTextAsset();">
            <div class="uk-margin-small">
              <label class="uk-form-label" for="text-name">{{ $t( 'Pages.AppEdit.Labels.AssetName') }} <span class="uk-text-danger">*</span></label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-expand">
                  <input
                    class="uk-input"
                    :class="{ 'invalid-border': errors.textAsset.name }"
                    type="text"
                    id="text-name1"
                    :placeholder="$t('Pages.AppEdit.Labels.AssetName')"
                    v-model.trim="forms.textAsset.name"
                    autocomplete="off"
                    @keyup="validateInput('textAsset', 'name')"
                    @keydown="validateInput('textAsset', 'name')"
                    @blur="checkFieldOnBlur('textAsset', 'name')"
                  />
                  <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.textAsset.name" uk-icon="icon: warning"></span>
                  <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.textAsset.name" >{{ errors.textAsset.name}}</span>
                </div>
              </div>
            </div>
            <div class="uk-margin-small">
              <label class="uk-form-label" for="text-name">{{ $t( 'Pages.AppEdit.Labels.AssetDescription') }} <span class="uk-text-danger">*</span></label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-expand">
                  <textarea
                    class="uk-textarea"
                    type="text"
                    :class="{ 'invalid-border': errors.textAsset.description }"
                    id="text-name2"
                    :placeholder="$t( 'Pages.AppEdit.Labels.AssetDescription')"
                    v-model.trim="forms.textAsset.description"
                    autocomplete="off"
                    @keyup="validateInput('textAsset', 'description')"
                    @keydown="validateInput('textAsset', 'description')"
                    @blur="checkFieldOnBlur('textAsset', 'description')"
                  > </textarea>
                  <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.textAsset.description" uk-icon="icon: warning"></span>
                  <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.textAsset.description" >{{ errors.textAsset.description}}</span>
                </div>
              </div>
            </div>
              <div class="uk-inline uk-width-expand uk-margin-small">
              <label class="uk-form-label" for="app-key-visibility">{{ $t( 'Pages.AppEdit.Labels.TextAssetData' ) }} <span class="uk-text-danger">*</span></label>
              <div class="uk-form-controls">
                <textarea class="uk-textarea" id="app-key-value2" type="text" rows="5" v-model.trim="forms.textAsset.data"
                  :class="{ 'invalid-border': errors.textAsset.data }"
                  :placeholder="$t( 'Pages.AppEdit.Labels.TextAssetData' )"
                  @keyup="validateInput('textAsset', 'data')"
                  @keydown="validateInput('textAsset', 'data')"
                  @blur="checkFieldOnBlur('textAsset', 'data')"
                  ></textarea>
                  <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.textAsset.data" uk-icon="icon: warning"></span>
                  <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.textAsset.data" >{{ errors.textAsset.data}}</span>
              </div>
            </div>
          </form>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-right" type="button">{{ $t( 'Actions.Cancel' ) }}</button>
          <button v-if="!saving" class="uk-button uk-button-primary" type="button" @click.prevent="saveTextAsset();">{{ isUpdate ? $t('Actions.Update') : $t( 'Actions.Save' ) }}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
    </div>
</template>

<script>
import Notification from '@/components/util/Notification';

export default {
  name: 'TextAssetModal',
  props: {
    isUpdate: {
      type: Boolean
    },
    data: {
      type: Object
    },
    clearModalData: {
      type: Number
    },
    content: {
      type: String, 
      required: false
    }
  },
  data () {
    return {
      appId: this.$route.params.appId,
      saving: false,
      forms: {
        textAsset: {
          name: '',
          description: '',
          data: ''
        }
      },
      errors: {
        textAsset: {
          name: '',
          description: '',
          data: ''
        }
      }
    }
  },
  methods: {
    clearForm (type) {
      for (let key in this.forms[type]) {
        this.forms[type][key] = '';
        this.errors[type][key] = '';
      }
    },
    checkFieldOnBlur (type, field) {
      if (!this.forms[type][field] || !this.forms[type][field].length) {
        this.errors[type][field] = this.$t('Generic.Errors.Min2Chars');
      }
    },
    validateAssetData (type) {
      let isValid = true
      if (type === 'textAsset') {
        for (const key in this.errors.textAsset) {
          if (this.errors.textAsset[key]) {
            isValid = false
            return false
          }
        }
        if (!this.forms.textAsset.name || !this.forms.textAsset.name.length) {
          this.errors.textAsset.name = this.$t('Generic.Errors.Min2Chars');
          isValid = false;
        }
        if (!this.forms.textAsset.description || !this.forms.textAsset.description.length) {
          this.errors.textAsset.description = this.$t('Generic.Errors.Min2Chars');
          isValid = false;
        }
        if (!this.forms.textAsset.data || !this.forms.textAsset.data.length) {
          this.errors.textAsset.data = this.$t('Generic.Errors.Min2Chars');
          isValid = false;
        }
      }
      return isValid;
    },
    async saveTextAsset () {
      const isValid = this.validateAssetData('textAsset')
      if (!isValid) {
        return
      }
      if (!this.isUpdate) {
        const params = {
          name: this.forms.textAsset.name,
          description: this.forms.textAsset.description || undefined,
          text: this.forms.textAsset.data || '',
          owner: this.content ? { content: this.content }:{ application: this.appId }
        }
        this.saving = true;
        const response = await this.$store.dispatch('createApplicationAsset', params);
        if (response?.createApplicationAsset?.asset?.id ) {
          Notification.showNotification(this.$t("Pages.AppEdit.Success.AssetCreated").replace('{{type}}', 'Text'), this.$t("Pages.AppEdit.Success.AssetCreatedDesc").replace('{{name}}', response.createApplicationAsset.asset.name))
          window.UIkit.modal('#text-asset-modal').hide();
          this.clearForm('textAsset');
        }
        else {
          Notification.showNotification(this.$t("Pages.AppEdit.Error.AssetCreatedError"), response, 'error')
        }
        this.saving = false
      } else {
        const params = {
          asset: this.data.id,
          name: this.forms.textAsset.name,
          description: this.forms.textAsset.description || ''
        }
        if (this.forms.textAsset.data) {
          params.setValue = {
            text: this.forms.textAsset.data
          }
        }
        this.saving = true
        const response = await this.$store.dispatch('updateApplicationAsset', { input: params, contentId: this.content });
        if (response.updateApplicationAsset?.asset?.id) {
          Notification.showNotification(this.$t("Pages.AppEdit.Success.AssetUpdated").replace('{{type}}', 'Text'), this.$t("Pages.AppEdit.Success.AssetUpdatedDesc").replace('{{name}}', response.updateApplicationAsset?.asset?.name));
          window.UIkit.modal('#text-asset-modal').hide();
        }
        else {
          Notification.showNotification(this.$t("Pages.AppEdit.Error.AssetUpdatedError"), response, 'error');
        }
        this.saving = false;
      }
    },
    validateInput (type, field) {
      if (this.forms[type][field]?.length && this.forms[type][field].length < 2) {
        this.errors[type][field] = this.$t( 'Generic.Errors.Min2Chars');
      } else {
        this.errors[type][field] = false;
      }
    }
  },
  watch: {
    data () {
      if (this.isUpdate) {
        this.forms.textAsset.name = this.data.name;
        this.forms.textAsset.description = this.data.description;
        this.forms.textAsset.data = this.data.currentVersion.value;
      } else {
        this.clearForm('textAsset');
      }
    },
    clearModalData () {
      this.clearForm('textAsset');
      if (this.isUpdate) {
        this.forms.textAsset.name = this.data.name;
        this.forms.textAsset.description = this.data.description;
        this.forms.textAsset.data = this.data.currentVersion.value;
      }
    }
  }
}
</script>

<style scoped>
.invalid-icon {
    color: #f0506e;
}
</style>