<template>
  <div id="app-assets">
      <button style="padding-left:10px; padding-right:10px" class="uk-button uk-button-primary uk-width-small uk-align-right uk-padding-remove" type="button">
        {{$t("Pages.AppEdit.Labels.NewAsset")}}<i uk-icon="icon: triangle-down"></i> 
      </button>
      <div uk-dropdown="mode: hover; pos: bottom-right; offset: 10; animation: uk-animation-slide-top-small; flip:true" id="add-asset-dropdown">
        <ul class="uk-nav uk-dropdown-nav uk-text-left">
          <li class="uk-text-left uk-text-default" :class="assetType === selectedAssetType? 'uk-active uk-text-bold': '' " v-for="assetType in assetTypes" :key="assetType" :value="assetType" > <a @click="selectAssetType(assetType)"> {{ assetType }} </a> </li>
        </ul>
      </div>
    <div 
      class="uk-grid-small uk-margin-remove-top uk-width-expand uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl"
      uk-scrollspy="target: > div.uk-grid-item-match; cls: uk-animation-scale-up; delay: 50; offset-top: 2000;"
      uk-grid
      v-if="!fetching && appAssets && appAssets.length"
    >
      <div 
        v-for="(asset, i) in appAssets"
        :key="i"
        class="uk-grid-item-match"
      >
          <AppAssetCard
          @edit="openEditModal"
            :assetData="asset"
            :updatedAt="asset.currentVersion.versionDate"
            :content="content"
          />
      </div>
    </div>
    <span v-else-if="(!appAssets || !appAssets.length) && !fetching" class="uk-text-meta uk-text-large">{{$t('Generic.Messages.NoData')}}</span>
    <div v-if="fetching">
      <span uk-spinner></span>
    </div>
    <text-asset-modal container="app-assets" :appId="appId" :isUpdate="isUpdate" :data="modalData" :clearModalData="clearModalData" :content="content? content.id : null"/>
    <media-asset-modal container="app-assets" :appId="appId" :isUpdate="isUpdate" :data="modalData" :clearModalData="clearModalData" :type="type" :content="content? content.id : null"/>
  </div>
  
</template>

<script>
import { mapState } from 'vuex';
import AppAssetCard from '@/components/cards/AppAssetCard.vue';
import TextAssetModal from '@/components/modals/TextAssetModal.vue';
import MediaAssetModal from '@/components/modals/MediaAssetModal.vue';

export default {
  components: {
    AppAssetCard,
    TextAssetModal,
    MediaAssetModal
  },
  props: {
    appData: {
      type: Object
    },
    content: { type: Object, required: false }
  },
  data () {
    return {
      assetTypes: [this.$t('Pages.AppEdit.Labels.TextAsset'), this.$t('Pages.AppEdit.Labels.ImageAsset'), this.$t('Pages.AppEdit.Labels.VideoAsset'), this.$t('Pages.AppEdit.Labels.FileAsset')],
      clearModalData: 0,
      selectedAssetType: '',
      appId: this.$route.params.appId,
      isUpdate: false,
      type: '',
      modalData: {},
      fetching: false,
      orgId: this.$route.params.id
    }
  },
  computed: {
    ...mapState({
      applications: state => state.venom.org?.orgApps?.nodes || []
    }),
    ...mapState({
      assets: state => state.venom.org?.appAssets || []
    }),
    appAssets () {
      const assets =  this.content ? this.content?.ref?.assets : this.assets;
      return [...assets]?.sort((a, b) => a.name.toString().localeCompare(b.name.toString().toString())) || '';
    }
  },
  async created() {
    if ( !this.content )
      this.fetchAppAssets()
  },
  methods: {
    async fetchAppAssets () {
      this.fetching = true
      await this.$store.dispatch('getApplicationAssets', { appId: this.appId })
      this.fetching = false
    },
    selectAssetType (assetType) {
      window.UIkit.dropdown("#add-asset-dropdown").hide()
      switch (assetType) {
        case this.$t('Pages.AppEdit.Labels.TextAsset'): this.newTextAsset();
          break;
        case this.$t('Pages.AppEdit.Labels.ImageAsset'): this.newImageAsset();
          break;
        case this.$t('Pages.AppEdit.Labels.VideoAsset'): this.newVideoAsset();
          break;
        case this.$t('Pages.AppEdit.Labels.FileAsset'): this.newFileAsset();
          break;
      }
    },
    newTextAsset () {
      this.isUpdate = false
      this.modalData = {}
      this.clearModalData++
      this.type = 'TEXT'
      window.UIkit.modal(`#text-asset-modal`).show();
    },
    newImageAsset () {
      this.isUpdate = false;
      this.modalData = {};
      this.clearModalData++;
      this.type = 'IMAGE'
      window.UIkit.modal('#media-asset-modal').show();
    },
    newVideoAsset () {
      this.isUpdate = false;
      this.modalData = {};
      this.clearModalData++;
      this.type = 'VIDEO'
      window.UIkit.modal('#media-asset-modal').show();
    },
    newFileAsset () {
      this.isUpdate = false;
      this.modalData = {};
      this.clearModalData++;
      this.type = 'FILE'
      window.UIkit.modal('#media-asset-modal').show();
    },
    openEditModal (asset) {
      let modalKey = ''
      switch(asset.type) {
        case 'TEXT': modalKey='#text-asset-modal';
          break;
        case 'IMAGE': modalKey='#media-asset-modal';
          break;
        case 'VIDEO': modalKey='#media-asset-modal';
          break;
        case 'FILE': modalKey='#media-asset-modal';
          break;
      }
      this.type = asset.type
      this.modalData = asset;
      this.isUpdate = true;
      this.clearModalData++;
      window.UIkit.modal(modalKey).show();
    }
  }
}
</script>

<style lang="scss" scoped>
.uk-dropdown {
  min-width: 150px;
}
</style>
