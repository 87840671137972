<template>
  <div class="uk-card uk-card-default uk-card-small uk-text-left uk-card-hover uk-border-rounded">
    <div class="uk-card-body card-body-max-height">
      <div class="uk-text-center">
        <div class="" style="height:60px">
          <ImageOrFallback
            v-if="isImage"
            :src="getImageUrl"
            :alt="assetName"
            :style="'height:55px; width:100px'"
          />
          <i v-else-if="isVideo" class="bi bi-film art-wrapper" style="color: #494747"></i>
          <i v-else-if="isFile" uk-icon="icon: file-text; ratio:2.8" style="color: #494747" class="art-wrapper"></i>
          <i v-else uk-icon="icon: file-edit; ratio:2.8" style="color: #494747" class="art-wrapper"></i>
          <!-- <div v-else style="height:55px; width=100px;" class=""><span class="vertical-center"> <h3 class="uk-text-muted">({{$t("Pages.AppEdit.Labels.TextAsset")}})</h3> </span></div> -->
        </div>
        <div class="uk-margin-small-top uk-width-expand vertical-center"> 
          <h5 class="uk-card-title uk-margin-remove-top uk-margin-remove-bottom uk-text-center uk-text-truncate uk-text-lead"
            :uk-tooltip="getToolTipText" style="font-size:1rem">{{assetName}}</h5>
        </div>
        <div class="uk-text-meta">
          {{$t('Pages.AppEdit.Labels.UpdatedAt')}} : {{updatedAt | date}}
        </div>
      </div>
    </div>
    <div class="uk-card-footer">
      <div class="uk-text-meta uk-float-right">
        <span v-if="deleting" uk-spinner="ratio: 1" />
        <div v-else uk-icon="trash" id=js-modal-confirm @click="deleteConfirmation()" class="uk-link"></div>
      </div>
      <div class="uk-text-meta uk-float-right uk-margin-small-right">
        <span v-if="deleting" uk-spinner="ratio: 1" />
        <div v-else uk-icon="pencil" class="uk-link" @click="openEditModal()"></div>
      </div>
      <div class="uk-flex-inline">
        <label class="uk-text-bold">{{ $t( `enums.MediaClass.${this.assetData.type}` ) }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import ImageOrFallback from '@/components/images/ImageOrFallback.vue';
import Notification from '@/components/util/Notification';

export default {
  components: {
    ImageOrFallback
  },
  data() {
    return {
      name: this.assetData.name,
      description: this.assetData.description,
      versionDate: this.updatedAt,
      imageClass: '',
      deleting: false
    }
  },
  props: {
    assetData: {
        type: Object
    },
    updatedAt: {
      type: String
    },
    content: {
      type: Object, required: false
    }
  },
  computed: {
    getImageUrl () {
      return this.assetData.currentVersion?.file?.url || null
    },
    isImage () {
      return this.assetData.type === 'IMAGE'
    },
    isVideo () {
      return this.assetData.type === 'VIDEO'
    },
    isFile () {
      return this.assetData.type === 'FILE'
    },
    getToolTipText () {
      const nameArr = this.assetData.name.split(' ')
      if (nameArr[0].length > 20) {
        nameArr.unshift(nameArr[0].substring(0, 20))
        nameArr[1] = nameArr[1].substring(20, nameArr[1].length)
      }
      return nameArr.join(' ')
    },
    assetName () {
      return this.assetData.name
    }
  },
  watch: {
    updatedAt () {
      this.name = this.assetData.name,
      this.description =  this.assetData.description
    }
  },
  methods: {
    deleteConfirmation () {
      window.UIkit.modal.confirm(this.$t('Pages.AppEdit.Labels.DeleteConfirmation').replace('{{name}}', this.name), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }}).then(() => {
        this.deleteAppAsset()
      }, () => {
      });
    },
    openEditModal() {
      this.$emit('edit', this.assetData);
    },
    async deleteAppAsset () {
      const params = this.content ? {
        asset: this.assetData.id,
        content: this.content.id
      } : {
        asset: this.assetData.id,
      }
      const response = await this.$store.dispatch('deleteApplicationAsset', params )
      if (response.asset?.id) {
        Notification.showNotification(this.$t('Pages.AppEdit.Success.AssetDeleted').replace('{{type}}', response.asset?.type), this.$t('Pages.AppEdit.Success.AssetDeletedDesc').replace('{{name}}', response.asset?.name))
      } else {
        Notification.showNotification(this.$('Pages.AppEdit.Error.DeleteAssetError'), response, 'error')
      }
    }
  }
}
</script>

<style scoped>
  .art-wrapper {
    height: 55px;
    width: 100px;
    font-size: 3.45rem;
  }
  .card-body-max-height {
    height: 110px;
  }
.vertical-center {
  position: relative;
  top: 35%;
  -ms-transform: translateY(+1%);
  transform: translateY(+1%);
}
</style>
