<template>
  <div id="media-asset-modal" :container="content ? '#content-details' : '#app-edit'" uk-modal="bg-close: false; esc-close: false; stack: true">
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <div class="uk-modal-header">
        <h2 class="uk-modal-title uk-text-left">{{modalTitle}}</h2>
      </div>
      <div class="uk-modal-body uk-flex uk-flex-column">
          <div class="uk-flex uk-flex-column uk-flex-middle">
            <div class="uk-position-relative uk-width-medium" style="margin-bottom: 25px">
              <ImgOrFallback v-if="type === 'IMAGE'" class="uk-margin-bottom" style="" :src="imageURL || ''"  height="160px" width="250px"/>
              <i v-else-if="type === 'VIDEO' && !videoURL" class="bi bi-film art-wrapper" style="color: #494747"></i>
              <video v-else-if="type === 'VIDEO'" :src="videoURL || ''" class="bi bi-film art-wrapper" uk-video="autoplay: false" controls=true></video>
              <i v-else-if="type === 'FILE'" uk-icon="icon: file-text; ratio: 3.45" style="color: #494747"></i>
              <div class="uk-position-bottom-out  uk-width-medium">
                <span v-if="errors.asset.media" class="uk-text-danger uk-margin-small-top ">{{$t('Pages.AppEdit.Error.MediaContentMandatory')}} </span>
              </div>
            </div>
          <button class="uk-button uk-button-primary uk-float-right uk-width-auto" @click="openMediaPicker()">{{ mediaButtonLabel }}</button>
        </div>
        <form id="new-folder" class="uk-form-stacked uk-text-left" @submit.prevent="saveMediaAsset();">
            <button class="uk-modal-close-default" type="button" uk-close ></button>

            <div class="uk-margin">
                <label class="uk-form-label">{{ $t( 'Pages.AppEdit.Labels.AssetName') }} <span class="uk-text-danger">*</span></label>
                <div class="uk-form-controls">
                  <div class="uk-inline uk-width-expand">
                    <input
                      class="uk-input"
                      :class="{ 'invalid-border': errors.asset.name }"
                      type="text"
                      id="text-name1"
                      :placeholder="$t('Pages.AppEdit.Labels.AssetName')"
                      v-model.trim="forms.asset.name"
                      autocomplete="off"
                      @keyup="validateInput('asset', 'name')"
                      @keydown="validateInput('asset', 'name')"
                      @blur="checkFieldOnBlur('asset', 'name')"
                    />
                    <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.asset.name" uk-icon="icon: warning"></span>
                    <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.asset.name" >{{ errors.asset.name}}</span>
                    
                  </div>
                </div>
            </div>

            <div class="uk-margin">
                <label class="uk-form-label">{{ $t( 'Pages.AppEdit.Labels.AssetDescription') }}</label>
                <div class="uk-form-controls">
                  <div class="uk-inline uk-width-expand">
                    <textarea
                      class="uk-textarea"
                      type="text"
                      id="text-name2"
                      :placeholder="$t( 'Pages.AppEdit.Labels.AssetDescription')"
                      v-model.trim="forms.asset.description"
                      autocomplete="off"
                    > </textarea>
                    <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.asset.description" uk-icon="icon: warning"></span>
                  </div>
                </div>
            </div>

            <div class="uk-margin" v-if="fileOrVideoURL">
                <label class="uk-form-label">{{ videoURL ? $t('Pages.AppEdit.Labels.VideoSource') : $t( 'Pages.AppEdit.Labels.FileURL') }} <span uk-icon="copy" style="cursor:pointer" class="uk-float-right" :uk-tooltip="$t('Actions.Copy')" @click="copyToClipboard(fileOrVideoURL)"></span> </label>
                <div class="uk-form-controls uk-margin-remove-top">
                  <div class="uk-inline uk-width-expand">
                    <input
                      class="uk-input uk-text-truncate"
                      type="text"
                      id="text-name3"
                      v-model="fileOrVideoURL"
                      autocomplete="off"
                      disabled
                    />
                  </div>
                </div>
            </div>
        </form>
      </div>
      <div class="uk-modal-footer uk-text-right">
        <button class="uk-button uk-button-default uk-modal-close uk-margin-right" @click="clearForm('asset')">{{ $t('Actions.Cancel') }}</button>
        <button v-if="!isLoading" class="uk-button uk-button-primary" :disabled="false" @click="saveMediaAsset()">{{ $t('Actions.Save') }}</button>
        <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
      </div>
      
      <div v-if="showPicker" id="media-picker-asset-modal" container="#app-assets" uk-modal>
        <div class="uk-modal-dialog uk-modal-body uk-width-3-4">
          <ModalMediaPicker :media-class-filter="assetType" @cancel="closeModal()" @select="selectedMedia( $event )" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalMediaPicker from '@/components/modals/ModalMediaPicker.vue';
import ImgOrFallback from '@/components/images/ImageOrFallback';
import Notification from '@/components/util/Notification';

export default {
  name: 'MediaAssetModal',
  props: {
    isUpdate: {
      type: Boolean
    },
    data: {
      type: Object
    },
    clearModalData: {
      type: Number
    },
    type: {
      type: String
    },
    content: {
      type: String, 
      required: false
    }
  },
  components: {
    ModalMediaPicker,
    ImgOrFallback
  },
  data () {
    return {
      isLoading: false,
      showPicker: false,
      orgId: this.$route.params.id,
      appId: this.$route.params.appId,
      forms: {
        asset: {
          name: '',
          description: '',
          media: '',
          newMedia: ''
        }
      },
      errors: {
        asset: {
          name: '',
          media: ''
        }
      }
    }
  },
  computed: {
    assetType () {
      if (['IMAGE', 'VIDEO', 'FILE'].includes(this.type)) {
        return this.type;
      } else {
        return 'IMAGE';
      }
    },
    imageURL () {
      if (this.type === 'IMAGE') {
        if (this.forms.asset.newMedia) {
          return this.forms.asset.media.file.url;
        }
        return this.forms.asset.media?.currentVersion?.file?.url;
      } else {
        return '';
      }
    },
    videoURL () {
      if (this.type === 'VIDEO') {
        if (this.forms.asset.newMedia) {
          return this.forms.asset.media?.file.url;
        }
        return this.forms.asset?.media?.currentVersion?.file?.url;
      } else {
        return '';
      }
    },
    fileURL () {
      if (this.assetType === 'FILE') {
        if (this.forms.asset.newMedia) {
          return this.forms.asset.media?.file.url;
        }
        return this.forms.asset?.media?.currentVersion?.file?.url;
      } else {
        return '';
      }
    },
    fileOrVideoURL () {
      return this.videoURL || this.fileURL;
    },
    modalTitle () {
      if (this.assetType === 'IMAGE') {
        if (this.isUpdate) return this.$t('Pages.AppEdit.Labels.EditImageAsset')
        else return this.$t('Pages.AppEdit.Labels.ImageAsset');
      } else if (this.assetType === 'VIDEO') {
        if (this.isUpdate) return this.$t('Pages.AppEdit.Labels.EditVideoAsset')
        else return this.$t('Pages.AppEdit.Labels.VideoAsset');
      } else {
        if (this.isUpdate) {
          return this.$t('Pages.AppEdit.Labels.EditFileAsset');
        } else return this.$t('Pages.AppEdit.Labels.FileAsset');
      }
    },
    mediaButtonLabel () {
      if (this.assetType === 'IMAGE') {
        if (this.isUpdate) return this.$t('Pages.AppEdit.Labels.ChangeImage')
        else return this.$t('Pages.AppEdit.Labels.AddImage');
      } else if (this.assetType === 'VIDEO') {
        if (this.isUpdate) return this.$t('Pages.AppEdit.Labels.ChangeVideo')
        else return this.$t('Pages.AppEdit.Labels.AddVideo');
      } else {
        if (this.isUpdate) return this.$t('Pages.AppEdit.Labels.ChangeFile')
        else return this.$t('Pages.AppEdit.Labels.AddFile');
      }
    }
  },
  methods: {
    copyToClipboard (text) {
      navigator.clipboard.writeText(text);
      Notification.showNotification(this.$t('Pages.AppEdit.Labels.Copied'), this.$t('Pages.AppEdit.Labels.CopiedToClipboard'));
    },
    clearForm (type) {
      for (let key in this.forms[type]) {
        this.forms[type][key] = '';
        this.errors[type][key] = '';
      }
    },
    validateInput (type, field) {
      if (this.forms[type][field]?.length && this.forms[type][field].length < 2) {
        this.errors[type][field] = this.$t( 'Generic.Errors.Min2Chars');
      } else {
        this.errors[type][field] = false;
      }
    },
    checkFieldOnBlur (type, field) {
      if (!this.forms[type][field] || !this.forms[type][field].length) {
        this.errors[type][field] = this.$t('Generic.Errors.Min2Chars');
      }
    },   
    validateAssetData () {
      let isValid = true
      for (const key in this.errors.asset) {
        if (this.errors.asset[key]) {
          isValid = false;
          return false;
        }
      }
      if (!this.forms.asset.name || !this.forms.asset.name.length) {
        this.errors.asset.name = this.$t('Generic.Errors.Min2Chars');
        isValid = false;
      }
      if (!this.forms.asset.media || !Object.keys(this.forms.asset.media).length) {
        this.errors.asset.media = this.$t('Pages.AppEdit.Errors.MediaContentMandatory');
        isValid = false;
        return isValid;
      }
      return isValid;
    },
    async saveMediaAsset () {
      const validationResponse = this.validateAssetData();
      if (!validationResponse) return false;
      if (!this.isUpdate) {
        const params = {
          name: this.forms.asset.name,
          description: this.forms.asset.description || undefined,
          owner: this.content ? { content: this.content } : { application: this.appId }
        }
        if (this.assetType === 'IMAGE') {
          params.image = {
            id: this.forms.asset.media?.id
          }
        } else if (this.assetType === 'VIDEO') {
          params.video = {
            id: this.forms.asset.media?.id
          }
        } else {
          params.file = {
            id: this.forms.asset.media?.id
          }
        }
        this.isLoading = true;
        const response = await this.$store.dispatch('createApplicationAsset', params);
        if (response.createApplicationAsset?.asset?.id) {
          Notification.showNotification(this.$t("Pages.AppEdit.Success.AssetCreated").replace('{{type}}', this.type), this.$t("Pages.AppEdit.Success.AssetCreatedDesc").replace('{{name}}', response.createApplicationAsset.asset.name));
          window.UIkit.modal('#media-asset-modal').hide();
          this.clearForm('asset');
          if(this.content)
            await this.$store.dispatch('getContentItem',{contentId: this.content});

        } else {
          Notification.showNotification(this.$t("Pages.AppEdit.Error.AssetCreatedError"), response?.errors[0]?.message, 'error');
        }
        this.isLoading = false;
      } else {
        const params = {
          asset: this.data.id,
          name: this.forms.asset.name,
          description: this.forms.asset.description || ''
        }
        if (this.forms.asset.media && this.forms.asset.media.id && this.forms.asset.newMedia) {
          params.setValue = {
            media: {
              id: this.forms.asset.media?.id
            }
          }
        }
        this.isLoading = true;
        const response = await this.$store.dispatch('updateApplicationAsset', { input: params, contentId: this.content });
        if (response.updateApplicationAsset?.asset?.id) {
          Notification.showNotification(this.$t("Pages.AppEdit.Success.AssetUpdated").replace('{{type}}', this.type), this.$t("Pages.AppEdit.Success.AssetUpdatedDesc").replace('{{name}}', response.updateApplicationAsset?.asset?.name));
          window.UIkit.modal('#media-asset-modal').hide();
        }
        else {
          Notification.showNotification(this.$t("Pages.AppEdit.Error.AssetUpdatedError"), response, 'error');
        }
        this.isLoading = false;
      }
    },
    openMediaPicker () {
      this.showPicker = true;
      setTimeout( () => {
        window.UIkit.modal('#media-picker-asset-modal').show();
      }, 100 );
    },
    selectedMedia (media) {
      this.forms.asset.newMedia = true;
      if (this.type === 'IMAGE') {
        this.forms.asset.media = media;
        this.forms.asset.name = media.title || this.forms.asset.name;
      } else if (this.type === 'VIDEO') {
        this.forms.asset.media = media.__typename === 'MediaBundle' ? media.masterVideo : media;
        this.forms.asset.name =  media.name || this.forms.asset.name;
      } else {
        this.forms.asset.media = media;
        this.forms.asset.name = media.title || media.filename || this.forms.asset.name;
      }
      this.validateInput('asset', 'name');
      this.validateInput('asset', 'media');
      this.closeModal();
    },
    closeModal () {
      window.UIkit.modal('#media-picker-asset-modal').hide();
      window.UIkit.modal('#media-asset-modal').show();
      setTimeout( function() {
        this.showPicker = false;
      }, 100 );
    },
  },
  watch: {
    data () {
      if (this.isUpdate) {
        this.forms.asset.name = this.data.name;
        this.forms.asset.description = this.data.description;
        this.forms.asset.media = this.data;
      } else {
        this.clearForm('asset');
      }
    },
    clearModalData () {
      this.clearForm('asset');
      if (this.isUpdate) {
        this.forms.asset.name = this.data.name;
        this.forms.asset.description = this.data.description;
        this.forms.asset.media = this.data;
      }
    }
  }
}
</script>

<style scoped>
  .art-wrapper {
    height: 200px;
    width: 300px;
    font-size: 3.45rem;
  }
  .invalid-icon {
      color: #f0506e;
  }
</style>